import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import requestor from "@/utils/requestor"
import api from '@/api'
import { Toast } from 'vant';
import GlobalFilter from '@/utils/filter'
import Tools from '@/utils/tools'
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard)

Vue.use(Toast);

Vue.use(Vant)

Vue.use(GlobalFilter)

Vue.prototype.$Toast = Toast

Vue.prototype.$Tools = Tools

Vue.config.productionTip = false

Vue.prototype.$api = api

Vue.prototype.$requestor = requestor

if (process.env.VUE_APP_BASE_URL != "") {
  Vue.prototype.$requestor.setBaseUrl(process.env.VUE_APP_BASE_URL)
} else {
  Vue.prototype.$requestor.setBaseUrl('https://api.' + location.host + '/api')
}

Vue.prototype.setToken = value => {
  Vue.prototype.$requestor.setToken(value);
  localStorage.setItem(process.env.VUE_APP_TOKEN_KEY, value);
}

Vue.prototype.cancelAll = value => {
  Vue.prototype.$requestor.cancelAll() 
}

Vue.prototype.removeToken = value => {
  Vue.prototype.$requestor.removeToken();
  localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
}

if (localStorage.getItem(process.env.VUE_APP_TOKEN_KEY)) {
  Vue.prototype.$requestor.setToken(localStorage.getItem(process.env.VUE_APP_TOKEN_KEY))
}

Vue.prototype.$requestor.errorHandle[401] = () => {
  Vue.prototype.$requestor.cancelAll() 
  localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY)
}
Vue.prototype.$requestor.errorHandle[500] = () => {
  Vue.prototype.$requestor.cancelAll() 
  Vue.prototype.$Toast.clear()
}
Vue.prototype.$requestor.errorHandle['timeout'] = () => {
  Vue.prototype.$requestor.cancelAll() 
  Vue.prototype.$Toast.clear()
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
