import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/homeLayout"),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home')
      },
      {
        path: '/bit-game',
        name: 'BitGame',
        component: () => import('@/views/win')
      },
      {
        path: '/my',
        name: 'My',
        component: () => import('@/views/my')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/login"),
  },
  {
    path: '/register/:code?',
    name: 'Register',
    component: () => import("@/views/register"),
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import("@/views/change-password"),
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import("@/views/recharge"),
  },
  {
    path: '/recharge-record',
    name: 'RechargeRecord',
    component: () => import("@/views/recharge-record"),
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import("@/views/withdraw"),
  },
  {
    path: '/withdraw-detail',
    name: 'WithdrawDetail',
    component: () => import("@/views/withdraw-detail"),
  },
  {
    path: '/withdraw-record',
    name: 'WithdrawRecord',
    component: () => import("@/views/withdraw-record"),
  },
  {
    path: '/promotion',
    name: 'Promotion',
    component: () => import("@/views/promotion-center"),
  },
  {
    path: '/private-policy',
    name: 'PrivatePolicy',
    component: () => import("@/views/private-policy"),
  },
  {
    path: '/bank',
    name: 'Bank',
    component: () => import("@/views/bank"),
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: () => import("@/views/introduction"),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import("@/views/reset-password"),
  },
  {
    path: '/game-record',
    name: 'GameRecord',
    component: () => import("@/views/game-record"),
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import("@/views/message"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  to.name && (document.title = to.name)
  next();
})

export default router
