import requestor from "@/utils/requestor"

export default {
  async getLanguageList() {
    return await requestor._get('/common/getLanguage')
  },
  async getConfig() {
    return await requestor._get('/common/getConfig')
  },
  async getService() {
    return await requestor._get('/common/getService')
  },
  async getBanner(data) {
    return await requestor._post('/common/getBanner', data)
  },
  async getLotteryType(data) {
    return await requestor._post('/lottery/info/getType', data)
  },
  async getLotteryRecord(data) {
    return await requestor._post('/lottery/info/getRecord', data)
  },
  async getLotteryInfo(data) {
    return await requestor._post('/lottery/info/getLottery', data)
  },
  async getTermService() {
    return await requestor._get('/common/getTermsOfService')
  },
  async login(data) {
    return await requestor._post('/user/login/login', data)
  },
  async register(data) {
    return await requestor._post('/user/login/register', data)
  },
  async getUserInfo() {
    return await requestor._post('/user/personal/userInfo')
  },
  async createBetting(data) {
    return await requestor._post('/lottery/betting/create', data)
  },
  async getBettingRecord(data) {
    return await requestor._post('/lottery/betting/record', data)
  },
  async getCurrencyRate() {
    return await requestor._get('/common/getCurrencyRate')
  },
  async getShareRule() {
    return await requestor._get('/common/getShareRule')
  },
  async getRechargeConfig() {
    return await requestor._get('/common/getDepositConfig')
  },
  async getWithdrawConfig() {
    return await requestor._get('/common/getWithdrawConfig')
  },
  async recharge(data) {
    return await requestor._post('/user/wallet/deposit', data)
  },
  async setPassword(data) {
    return await requestor._post('/user/personal/setPassword', data)
  },
  async getActivityList() {
    return await requestor._get('/activity/airdrop/getList')
  },
  async readActivity(data) {
    return await requestor._post('/activity/airdrop/read', data)
  },
  async receiveActivity(data) {
    return await requestor._post('/activity/airdrop/receive', data)
  },
  async getMessageList(data) {
    return await requestor._post('/user/message/getMessage', data)
  },
  async readMessage(data) {
    return await requestor._post('/user/message/read', data)
  },
  async withdraw(data) {
    return await requestor._post('/user/wallet/withdraw', data)
  },
  async getRechargeOrder(data) {
    return await requestor._post('/user/wallet/depositOrder', data)
  },
  async getWithdrawOrder(data) {
    return await requestor._post('/user/wallet/getWithdrawOrder', data)
  },
  async sendEmailCode(data) {
    return await requestor._post('/user/login/sendEmailCode', data)
  },
  async setEmail(data) {
    return await requestor._post('/user/personal/setEmail', data)
  },
  async changePassword(data) {
    return await requestor._post('/user/login/changePassword', data)
  },
  async getNotStakingMiningStat(data) {
    return await requestor._get('/activity/notStakingMining/getStat', data)
  },
  async getIncomeList(data) {
    return await requestor._post('/activity/notStakingMining/getIncomeList',data)
  },
  async getIncomeStat() {
    return await requestor._get('/user/personal/incomeStat')
  },
  async getMiningStat() {
    return await requestor._get('/common/getMiningStat')
  },
  async exchange(data) {
    return await requestor._post('/user/wallet/exchange', data)
  },
  async getShareStat(data) {
    return await requestor._post('/user/share/getStat', data)
  },
  async getService() {
    return await requestor._get('/common/getService')
  },
  async getWithdrawDetail(data) {
    return await requestor._post('/user/wallet/getWithdrawDetail', data)
  },
  async getKlineData(data) {
    return await requestor._post('/k_line/getList', data)
  }
}